import axios, { isCancel as isCancelRequest } from 'axios';
import qs from 'qs';

const csrfTokenElement = document.querySelector('meta[name=csrf-token]');
const csrfToken = csrfTokenElement ? csrfTokenElement.content : '';
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;
axios.defaults.paramsSerializer = params => qs.stringify(params, { arrayFormat: 'brackets' });

export { csrfToken, isCancelRequest };

export default axios;
