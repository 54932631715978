import React from 'react';
import { createRoot } from 'react-dom/client';
import T from 'prop-types';
import cx from 'classnames';
import { forEach, reduce } from 'lodash';

import axios from '../../../configs/axios';

const IconCaptionsRow = ({ data }) => data.map(({ src, caption, iconBordered }) => (
  <div key={src} className="marketing-icon-caption-block">
    <div className={cx('marketing-icon-caption-block-icon', iconBordered && 'icon-bordered')}>
      {src
        ? <img src={src} alt={caption} className="img-fluid" loading="lazy" />
        : <div className="marketing-icon-caption-block-icon-placeholder" />}
    </div>
    <div className="marketing-icon-caption-block-caption">{caption}</div>
  </div>
));

IconCaptionsRow.propTypes = {
  data: T.array.isRequired,
};

// Get initial data from the DOM
const getRowNodesData = (rootNode) => {
  const data = {};

  rootNode.querySelectorAll('.icon-captions-row').forEach((iconCaptionsRow) => {
    const { portalId } = iconCaptionsRow.dataset;

    iconCaptionsRow.querySelectorAll('.marketing-icon-caption-block').forEach((iconCaptionNode) => {
      const iconData = JSON.parse(iconCaptionNode.dataset.iconData);

      if (data[portalId]) data[portalId].data.push(iconData);
      else data[portalId] = { data: [iconData], node: iconCaptionsRow };
    });
  });

  return data;
};

// Render rows with icons
const renderRows = (rowsData, iconsData) => {
  forEach(rowsData, (rowData) => {
    const decoratedRowData = rowData.data.map((item) => {
      let src = item.icon_src || null;
      let iconBordered = false;

      if (item.data_link && iconsData[item.data_link]) {
        src = iconsData[item.data_link];
        iconBordered = true;
      }

      return ({
        ...item,
        src,
        iconBordered,
      });
    });

    const root = createRoot(rowData.node);
    root.render(<IconCaptionsRow data={decoratedRowData} />);
  });
};

document.addEventListener('DOMContentLoaded', () => {
  const rootNode = document.getElementById('group-overview-root');

  if (rootNode) {
    const rowsData = getRowNodesData(rootNode);

    const allUrls = reduce(rowsData, (acc, { data }) => (
      acc.concat(data.map(item => item.data_link).filter(Boolean))
    ), []);

    axios.post('/apps/icons', { urls: allUrls })
      .then(({ data }) => {
        renderRows(rowsData, data);
      })
      .catch((error) => {
        console.warn('error', error);
      });
  }
});
